import React from 'react'
import { Link } from 'gatsby'
import Moment from 'react-moment';

import styles from './article-preview.module.css'
function truncate(str,len) {
  return str.length > len ? str.substring(0, len-3) + "..." : str;
}


export default ({ article }) => (
  <div className={styles.preview}>
    <h3 className={styles.previewTitle}>
      <Link to={`/blog/${article.slug}`}>{truncate(article.postTitle, 100)}</Link>
    </h3>
    <small>{article.publishDate === 'None' ? <Moment parse="YYYY-MM-DD HH:mm">{article.createdLocal}</Moment> : <Moment>{article.publishDate}</Moment> }</small>
    <br></br>
    <small><strong>{article.feedName}</strong></small>
    <p
      dangerouslySetInnerHTML={{
        __html: truncate(article.postSummary.childMarkdownRemark.html,250),
      }}
    />
  </div>
)
