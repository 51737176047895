
import React from 'react'
import './base.css'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'

const Pagination = ({pageContext, pagePath}) => {
        const { currentPage, numPages } = pageContext
        const isFirst = currentPage === 1
        const isLast = currentPage === numPages
        let prevPage
        let nextPage
        if(pagePath) {
            prevPage = currentPage - 1 === 1 ? pagePath : pagePath + (currentPage - 1).toString()
            nextPage = pagePath + (currentPage + 1).toString()
        } else {
            prevPage = currentPage - 1 === 1 ? "/" : "/" + (currentPage - 1).toString()
            nextPage = "/" + (currentPage + 1).toString()
        }
        
        let needsTruncate = false

        if(numPages > 8) {
            needsTruncate = true
        }
        
        
      return (
        <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center flex-wrap">
                {!isFirst && (
                    <li className="page-item">
                        <Link to={prevPage} rel="prev" className="page-link">
                            ← Newer Articles
                    </Link>
                    </li>
                )}
                {needsTruncate = false &&
                    Array.from({ length: numPages }, (_, i) => (
                        <li className={"page-item" + (currentPage == i + 1 ? " active" : "")}>
                            <Link className="page-link" key={`pagination-number${i + 1}`} to={`/${i === 0 ? "" : i + 1}`}>
                                {i + 1}
                            </Link>
                        </li>
                    ))
                }
                {!isLast && (
                    <li className="page-item">
                        <Link to={nextPage} rel="next" className="page-link">
                            Older Articles →
                        </Link>
                    </li>
                )}
            </ul>
        </nav>
      )
  }
  export default Pagination
  

